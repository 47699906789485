body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  /*background: -moz-linear-gradient(top #000 0%, #dd9933 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #000000),
    color-stop(100%, #dd9933)
  );*/
  /*background: -webkit-linear-gradient(top, #000000 0%, #dd9933 100%);
  background: -o-linear-gradient(top, #000000 0%, #dd9933 100%);
  background: -ms-linear-gradient(top, #000000 0%, #dd9933 100%);
  background: linear-gradient(to bottom, #000000 0%, #dd9933 100%);*/
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000',endColorstr='#dd9933',GradientType=0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
